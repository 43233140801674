import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export const Media: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  
  return (
    <div className="container mx-auto p-4 text-white" id="media">
      <div className="w-full py-md-16">
       <header className="sticky top-0 z-50 w-full bg-black shadow-sm">
         <div className="container mx-auto px-4 flex h-14 items-center justify-between">
           <div className="flex items-center">
             <Link to="/" className="font-bold text-xl"><img src="/assets/icons/logo.svg" height="40px" alt=""></img></Link>

           </div>
           <nav className="hidden md:flex items-center space-x-6">
             <Link to="/" className="rounded-full btn-outline py-2 px-4 btn-cyan">Vision Board</Link>
             <Link to="/build-your-own" className="rounded-full btn-outline py-2 px-4 btn-orange">Build Your Own</Link>
           </nav>
           <button
             className="md:hidden p-2"
             onClick={() => setIsMenuOpen(!isMenuOpen)}
             aria-label="Toggle menu"
           >
             <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="white">
               <path d="M4 6h16M4 12h16M4 18h16"></path>
             </svg>
           </button>
         </div>
       </header>
       {isMenuOpen && (
         <div className="fixed inset-0 z-50 bg-black md:hidden">
           <div className="flex flex-col items-center justify-center text-center h-full">
             <Link to="/" className="rounded-full w-50 btn-outline text-xl text-white btn-cyan font-bold my-4 py-2 px-4" onClick={() => setIsMenuOpen(false)}>Vision Board</Link>
             <Link to="/build-your-own" className="rounded-full w-50 btn-outline text-xl text-white btn-orange font-bold my-4 py-2 px-4" onClick={() => setIsMenuOpen(false)}>Build Your Own</Link>
             <button
               className="absolute top-4 right-4 p-2"
               onClick={() => setIsMenuOpen(false)}
               aria-label="Close menu"
             >
               <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                 <path d="M6 18L18 6M6 6l12 12"></path>
               </svg>
             </button>
           </div>
         </div>
       )}
      </div>
      <h1 className="text-white oversize">Media <br/> Kits</h1>
      <p>
        <img src="/images/AUS-Top-5-celebs-in-Aussie-bios.jpg" className="my-5" alt="" />
        <img src="/images/BR-3-destinos.png" className="my-5" alt="" />
        <img src="/images/CAN-Tinder-Spotify-Anthems.png" className="my-5" alt="" />
        <img src="/images/DE-9.png" className="my-5" alt="" />
        <img src="/images/FR-Tinder-YIS24-Célébrités.png" className="my-5" alt="" />
        <img src="/images/GLOBAL-Tinder-DatingTrends.png" className="my-5" alt="" />
        <img src="/images/IT-Le-emoji-in-ascesa-nei-profili-Tinder.png" className="my-5" alt="" />
        <img src="/images/MX-Tinder-Media-Kits-Mayor-Crecimiento.png" className="my-5" alt="" />
        <img src="/images/NL-2024-Tinder-YIS-05.jpg" className="my-5" alt="" />
        <img src="/images/SP-5-Top-Artistas-Spotify-en-Tinder.png" className="my-5" alt="" />
        <img src="/images/SWE-Tinder-Passport-Swe.png" className="my-5" alt="" />
        <img src="/images/TH-Top-Foods-in-Thailand-ENG.jpg" className="my-5" alt="" />
        <img src="/images/UK-Tinder-Year-in-Swipes-04.png" className="my-5" alt="" />
        <img src="/images/US-Tinder-Spotify.png" className="my-5" alt="" />
      </p>
  </div>
  )
}

export default Media