import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import icons from './icons'

const SvgSpread: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [iconPositions, setIconPositions] = useState<Array<{ x: number, y: number }>>([])
  const [iconPosition, setIconPosition] = useState({ x: 0, y: 0 })
  const [modalTitle, setModalTitle] = useState("")
  const [modalName, setModalName] = useState("")
  const [modalBody, setModalBody] = useState("")
  const [modalColor, setModalColor] = useState("#2961EC")
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [resolution, setResolution] = useState(0)
  const [selectedIcon, setSelectedIcon] = useState<Icon | null>(null)

  type Icon = {
    id: number
    type: string
    name: string
    text: string
    component: object
    file: string
    x: object
    y: object
    scale: object
  }

   useEffect(() => {
     setIconPositions(icons.map((icon) => ({
      x: icon.x[resolution]/100 * 1900,
      y: icon.y[resolution]*4
     })))

     setResolution(window.innerWidth < 768 ? 1 : 0)
   }, [resolution])

   const openModal = (icon: Icon, event: React.MouseEvent<SVGGElement>) => {
     setIsOpen(true)
   }

   const closeModal = () => {
     setIsOpen(false)
     setSelectedIcon(null)
   }

   const hoverOverIcon = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
      // Select all elements with the "active" class
      const activeElements = document.querySelectorAll(".active");

      // Remove the "active" class from all of them
      activeElements.forEach((element) => {
        const svgElement = element as SVGElement; // Cast Element to SVGElement
        svgElement.classList.remove("active");
        updateScale(svgElement, -0.1); // Apply rotation
      });

     e.currentTarget.classList.add("active")
     updateScale(e.currentTarget,0.1)
   }

  const updateScale = (element: SVGElement, deltaScale: number) => {
    // Parse existing transform attribute
    const parseTransform = (transform: string | null) => {
      const result = {
        translate: { x: 0, y: 0 },
        scale: 1,
        rotate: 0,
      };

      if (transform) {
        const translateMatch = transform.match(/translate\(([-\d.]+)[, ]*([-\d.]+)?\)/);
        if (translateMatch) {
          result.translate.x = parseFloat(translateMatch[1]);
          result.translate.y = parseFloat(translateMatch[2] || "0");
        }

        const scaleMatch = transform.match(/scale\(([-\d.]+)\)/);
        if (scaleMatch) {
          result.scale = parseFloat(scaleMatch[1]);
        }

        const rotateMatch = transform.match(/rotate\(([-\d.]+)\)/);
        if (rotateMatch) {
          result.rotate = parseFloat(rotateMatch[1]);
        }
      }

      return result;
    };

    const transform = element.getAttribute("transform");
    const currentTransform = parseTransform(transform);

    // Add the delta angle to the current rotation
    currentTransform.scale += deltaScale;

    // Reassemble the transform attribute
    const newTransform = `
      translate(${currentTransform.translate.x}, ${currentTransform.translate.y})
      scale(${currentTransform.scale})
      rotate(${currentTransform.rotate})
    `.trim();

    // Apply the new transform to the element
    element.setAttribute("transform", newTransform);
   };
   const handleClick = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
   
      const currentTarget = e.currentTarget as SVGGElement;

      // Add the animation class
      const child = currentTarget.children[0]
      
      let transform = child.getAttribute('transform');
       
      if (transform) {
         var re = /\(([^\s}]+)\)/g
         var str = transform
         
         var m = re.exec(transform)
         if (!m) return 0

         const v = parseFloat(m[1])+0.01
         transform = `scale(${v})`
       
         currentTarget.children[0].setAttribute("transform", transform)

         setTimeout(() => {
           currentTarget.children[0].setAttribute("transform",str)
         }, 300); // Match this with the animation duration in CSS
      }
      console.log(e.currentTarget.id)
      const i = icons.filter((i) => i.id === parseInt(e.currentTarget.id))[0]
       
      if (i){
        const rect = e.currentTarget.getBoundingClientRect()
        setIconPosition({ x: rect.x, y: rect.y })
        setSelectedIcon(i)
        let color = "#2961EC"
        let title = "Vision Board Instructions.txt"


        switch (true) { 
           case i.type.includes('Trend'):
              color = "#FD267D"
              title = "2024 Tinder Trend Term.txt"
              break;
           case i.type.includes('Theme'):
              color = "#00BE6D"
              title = "2024 Major Themes.txt"
              break
           case i.type.includes('Data'):
              color = "#2961EC"
              title = "2024 Tinder Data.txt"
              break
        }

        setModalColor(color)
        setModalTitle(title)
        setModalName(i['name'])
        setModalBody(i['text'])

        if (i['text'] !== "" ) openModal(i,e)
      }
   };
   const showInfoButton = () => {
      const currentTarget = document.getElementById("73")
      if (currentTarget) {  
         // Add the animation class
         const child = currentTarget.children[0]
         
         let transform = child.getAttribute('transform');
          
         if (transform) {
            var re = /\(([^\s}]+)\)/g
            var str = transform
            
            var m = re.exec(transform)
            if (!m) return 0

            const v = parseFloat(m[1])+0.01
            transform = `scale(${v})`
          
            currentTarget.children[0].setAttribute("transform", transform)

            setTimeout(() => {
              currentTarget.children[0].setAttribute("transform",str)
            }, 300); // Match this with the animation duration in CSS
         }
         console.log(currentTarget.id)
         const i = icons.filter((i) => i.id === parseInt(currentTarget.id))[0]
          
         if (i){
           const rect = currentTarget.getBoundingClientRect()
           setIconPosition({ x: rect.x, y: rect.y })
           setSelectedIcon(i)

           
           setModalColor("#2961EC")
           setModalTitle("Vision Board Instructions.txt")
           setModalName(i['name'])
           setModalBody(i['text'])
           
           setIsOpen(true)
         }
      }

   }
   const [isMenuOpen, setIsMenuOpen] = useState(false)

   useEffect(() => {
      const handleResize = () => {
         if (window.innerWidth > 768) {
           setIsMenuOpen(false)
         }
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
   }, [])

   return (
      <div className="w-full h-screen py-md-16" id="home">
        <header className="sticky top-0 z-50 w-full bg-black shadow-sm">
          <div className="mx-auto px-4 flex h-14 items-center justify-between">
            <div className="flex items-center">
              <Link to="/" className="font-bold text-xl"><img src="/assets/icons/logo.svg" height="40px" alt=""></img></Link>

            </div>
            <nav className="hidden md:flex items-center space-x-6">
              <Link to="/build-your-own" className="rounded-full btn-outline py-2 px-4 btn-blue">Build Your Own</Link>
              <Link to="/media-kits" className="rounded-full btn-outline py-2 px-4 btn-orange">Media Kits</Link>
            </nav>
            <button
              className="md:hidden p-2"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle menu"
            >
              <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="white">
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        </header>
        {isMenuOpen && (
          <div className="fixed inset-0 z-50 bg-black md:hidden">
            <div className="flex flex-col items-center justify-center text-center h-full">
               <Link to="/build-your-own" className="btn-outline text-xl btn-orange rounded-full font-bold w-50 my-4 py-3" onClick={() => setIsMenuOpen(false)}>Build Your Own</Link>
               <Link to="/media-kits" className="btn-outline text-xl btn-blue rounded-full font-bold w-50 my-4 py-3" onClick={() => setIsMenuOpen(false)}>Media Kits</Link>
               <button
                 className="absolute top-4 right-4 p-2"
                 onClick={() => setIsMenuOpen(false)}
                 aria-label="Close menu"
               >
                 <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                   <path d="M6 18L18 6M6 6l12 12"></path>
                 </svg>
               </button>
             </div>
           </div>
         )}

         {isOpen && (
           <div className="fixed inset-0 z-50 flex items-center justify-center">
             <div className="relative z-40 max-w-md text-center">
               <div className="modal text-center p-5">
                   <svg width="469" height="319" viewBox="0 0 469 319" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <rect x="4" y="4" width="461" height="311" fill="#D9D9D9" stroke={modalColor} strokeWidth="8"/>
                     <g filter="url(#filter0_ii_81_5284)">
                       <g x={iconPosition.x} y={iconPosition.y}>{selectedIcon && selectedIcon.name}</g>
                       <rect width="469" height="39" fill={modalColor}/>

                       <g onClick={closeModal} className="cursor-pointer" transform="translate(440,5)">
                         <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <rect x="0.5" y="0.5" width="24" height="24" fill="#D9D9D9" stroke="black"/>
                           <path d="M20 6L5 21.9299" stroke="black" strokeWidth="2.19784" strokeLinecap="round" strokeLinejoin="round"/>
                           <path d="M5 6L20 21.9299" stroke="black" strokeWidth="2.19784" strokeLinecap="round" strokeLinejoin="round"/>
                         </svg>
                       </g>

                       <text x="30" y="30" fontSize="18" fontWeight="bold" fill="#fff" fontFamily="B612 Mono">{modalTitle}</text>

                       <foreignObject x="30" y="60" width="400" height="280">
                         <div className="text-lg text-gray-700 text-start">
                           <p><strong>{modalName}:</strong></p>
                           <p className="mt-4">{modalBody}</p>
                         </div>
                       </foreignObject>

                     </g>
                     <defs>
                       <filter id="filter0_ii_81_5284" x="-4" y="-4" width="477" height="470" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                         <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                         <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                         <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                         <feOffset dx="5" dy="4"/>
                         <feGaussianBlur stdDeviation="2"/>
                         <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                         <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                         <feBlend mode="normal" in2="shape" result="effect1_innerShadow_81_5284"/>
                         <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                         <feOffset dx="-4" dy="-4"/>
                         <feGaussianBlur stdDeviation="2"/>
                         <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                         <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                         <feBlend mode="normal" in2="effect1_innerShadow_81_5284" result="effect2_innerShadow_81_5284"/>
                       </filter>
                     </defs>
                   </svg>
               </div>
             </div>
           </div>
         )}
         <svg className="w-full h-[calc(120vh-8rem)]" viewBox={`0 0 1900 ${resolution ? 15600 : 4600}`} preserveAspectRatio="xMidYMid meet">
           {icons.map((icon, index) => {
             const position = iconPositions[index] || { x: 0, y: 0 }
             const IconComponent = icon.component
             return (
               <g
                 key={icon.id}
                 id={`${icon.id}`}
                 name={`${icon.name.replace(' ','_').toLowerCase()}`}
                 transform={`translate(${position.x}, ${position.y}) scale(${icon.scale[resolution]}) rotate(${icon.rotate[resolution]})`}
                 onMouseOver={hoverOverIcon}
                 onMouseDown={handleClick}
                 className="cursor-pointer"
               >
                 <IconComponent />
               </g>
             )
           })}
         </svg>
         {isModalOpen && (
           <div className="modal text-center">
             <div className="modal-content">
               <h4 className="mt-2 font-bold font-12">
                 Tinder Year In Swipe™
               </h4>
               <h1 className="mb-2 font-bold">
                 Vision Board
               </h1>
               <h3 className="m-2 font-bold">
                 Step into Tinder's Year in Swipe™ 2024 Vision Board to discover the dating trends that defined our year, plus our boldest 2025 predictions.
               </h3>
               <h3 className="m-2 font-bold">
                  Mix (and match) to create your own Vision Board and manifest big things for your dating and relationship goals in the year ahead.
               </h3>
               <div className="px-4 py-3 sm:px-6">
                 <button
                   type="button"
                   className="py-2 px-3 px-md-5 btn-pink rounded-full"
                   onClick={(e) => {setIsModalOpen(false);showInfoButton()}}
                 >
                   LET'S GO
                 </button>
               </div>
             </div>
           </div>
         )}

          <footer className="bg-black row text-white font-bold">
            <div className="col-md-6">
              <p><sup>1</sup>A survey of 4000 18-30 year olds who are actively dating in the US, UK, Canada and Australia between September 25, 2024 and November 4, 2024 conducted by OnePoll on behalf of Tinder.</p>

              <p>©2024 Tinder LLC. Tinder, Swipe, the Flame Logo, Super Like, It's a Match!, and Year in Swipe are all trademarks of Tinder LLC. All rights reserved.</p>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <a className="ms-3" href="https://policies.tinder.com/terms/intl/en/?lang=en">Terms of use</a>
              <a className="ms-3" href="https://policies.tinder.com/privacy/intl/en/">Privacy policy</a>
              <a className="ms-3" href="/cookies">Cookie policy</a>
            </div>
          </footer>
       </div>
   )
}

export default SvgSpread