import React, { useState, useRef, useCallback, useEffect } from 'react'
import { HelpCircle, Trash2 } from 'lucide-react'
import { Save } from './save'
import { useNavigate } from 'react-router-dom'
// import icons from './icons'

type IconType = {
  id: number
  name: string
  x: number
  y: number
  rotation: number
  scale: number
}

type TextBoxType = {
  id: number
  text: string
  x: number
  y: number
  rotation: number
  scale: number
}

const IconSVG = ({ name, className }: { name: string; className?: string }) => {
  const [svgContent, setSvgContent] = useState<string | null>(null)
  const [viewBox, setViewBox] = useState<string | null>(null)

  useEffect(() => {
    fetch(`/assets/icons/${name}`)
      .then(response => response.text())
      .then(text => {
        const parser = new DOMParser()
        const svgDoc = parser.parseFromString(text, 'image/svg+xml')
        const svgElement = svgDoc.querySelector('svg')
        if (svgElement) {
          setViewBox(svgElement.getAttribute('viewBox') || '-100 -100 100 100')
          setSvgContent(svgElement.innerHTML)
        } else {
          setSvgContent(null)
        }
      })
      .catch(() => setSvgContent(null))
  }, [name])

  if (svgContent) {
    return (
      <svg
        width="200"
        height="200"
        viewBox={viewBox || '0 0 200 200'}
        fill="none"
        dangerouslySetInnerHTML={{ __html: svgContent }}
        className={className}
      />
    )
  }
  return <HelpCircle className={className} />
}

const PhoneIcon: React.FC<{
  icon: IconType
  updateIcon: (id: number, updates: Partial<IconType>) => void
  deleteIcon: (id: number) => void
  isActive: boolean
  setActiveIcon: (id: number | null) => void
  setHeldIconId: (id: number | null) => void
  onDeleteAttempt: (id: number, x: number, y: number) => void;
  isOverTrashBin: boolean;
  onClick: (id: number, event: React.MouseEvent | React.TouchEvent) => void;
}> = ({ icon, updateIcon, deleteIcon, isActive, setActiveIcon, setHeldIconId, onDeleteAttempt, isOverTrashBin, onClick }) => {
  const [isMoving, setIsMoving] = useState(false)
  const [transformType, setTransformType] = useState<'nw' | 'ne' | 'sw' | 'se' | null>(null)
  const iconRef = useRef<SVGGElement>(null)

  const handleMouseDown = (event: React.MouseEvent | React.TouchEvent) => {
    event.stopPropagation();
    
    const isTouch = 'touches' in event;
    const startX = isTouch ? event.touches[0].clientX : event.clientX;
    const startY = isTouch ? event.touches[0].clientY : event.clientY;
    const startIconX = icon.x;
    const startIconY = icon.y;

    setActiveIcon(icon.id);
    setHeldIconId(icon.id);
    setIsMoving(true);

    const handleMove = (moveEvent: MouseEvent | TouchEvent) => {
      const moveX = 'touches' in moveEvent ? moveEvent.touches[0].clientX : moveEvent.clientX;
      const moveY = 'touches' in moveEvent ? moveEvent.touches[0].clientY : moveEvent.clientY;
      const dx = moveX - startX;
      const dy = moveY - startY;

      updateIcon(icon.id, { x: startIconX + dx / 2, y: startIconY + dy / 2 });
    };

    const handleEnd = (endEvent: MouseEvent | TouchEvent) => {
      setIsMoving(false);
      setHeldIconId(null);

      const endX = 'touches' in endEvent ? endEvent.changedTouches[0].clientX : endEvent.clientX;
      const endY = 'touches' in endEvent ? endEvent.changedTouches[0].clientY : endEvent.clientY;

      if (isOverTrashBin) {
        deleteIcon(icon.id);
      } else {
        onDeleteAttempt(icon.id, endX, endY);
      }

      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('touchmove', handleMove);
      window.removeEventListener('touchend', handleEnd);
    };

    window.addEventListener('mousemove', handleMove);
    window.addEventListener('mouseup', handleEnd);
    window.addEventListener('touchmove', handleMove);
    window.addEventListener('touchend', handleEnd);
  };


  const handleTransformStart = (
    event: React.MouseEvent | React.TouchEvent,
    corner: 'nw' | 'ne' | 'sw' | 'se'
  ) => {
    event.stopPropagation();
    setTransformType(corner);

    const isTouch = 'touches' in event;
    const startX = isTouch ? event.touches[0].clientX : event.clientX;
    const startY = isTouch ? event.touches[0].clientY : event.clientY;

    const iconRect = iconRef.current?.getBoundingClientRect();
    const centerX = iconRect ? iconRect.left + iconRect.width / 2 : icon.x + 100;
    const centerY = iconRect ? iconRect.top + iconRect.height / 2 : icon.y + 100;

    const initialDistance = Math.sqrt(
      Math.pow(startX - centerX, 2) + Math.pow(startY - centerY, 2)
    );
    const initialAngle = Math.atan2(startY - centerY, startX - centerX);
    const initialScale = icon.scale;

    const handleTransformMove = (moveEvent: MouseEvent | TouchEvent) => {
      const moveX = 'touches' in moveEvent ? moveEvent.touches[0].clientX : moveEvent.clientX;
      const moveY = 'touches' in moveEvent ? moveEvent.touches[0].clientY : moveEvent.clientY;

      const dx = moveX - centerX;
      const dy = moveY - centerY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const angle = Math.atan2(dy, dx);

      const newScale = (distance / initialDistance) * initialScale;
      const newRotation = ((angle - initialAngle) * 180) / Math.PI + icon.rotation;

      updateIcon(icon.id, { rotation: newRotation, scale: newScale });
    };

    const handleTransformEnd = (endEvent: MouseEvent | TouchEvent) => {
      const endX = 'touches' in endEvent ? endEvent.changedTouches[0].clientX : endEvent.clientX;
      const endY = 'touches' in endEvent ? endEvent.changedTouches[0].clientY : endEvent.clientY;

      onDeleteAttempt(icon.id, endX, endY);
      setTransformType(null);
      setActiveIcon(null);

      window.removeEventListener('mousemove', handleTransformMove);
      window.removeEventListener('mouseup', handleTransformEnd);
      window.removeEventListener('touchmove', handleTransformMove);
      window.removeEventListener('touchend', handleTransformEnd);
    };

    window.addEventListener('mousemove', handleTransformMove);
    window.addEventListener('mouseup', handleTransformEnd);
    window.addEventListener('touchmove', handleTransformMove);
    window.addEventListener('touchend', handleTransformEnd);
  };


  const getCirclePosition = (corner: 'nw' | 'ne' | 'sw' | 'se', icon: IconType) => {
    const size = 200 * icon.scale
    switch (corner) {
      case 'nw': return { cx: 0, cy: 0 }
      case 'ne': return { cx: size, cy: 0 }
      case 'se': return { cx: size, cy: size }
      case 'sw': return { cx: 0, cy: size }
    }
  }

  return (
    <g
      ref={iconRef}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      transform={`translate(${icon.x}, ${icon.y}) rotate(${icon.rotation} 100 100) scale(${icon.scale})`}
      style={{ cursor: isMoving ? 'grabbing' : 'grab', opacity: isOverTrashBin ? 0.5 : 1 }}
    >
      <IconSVG name={icon.name} />
      {isActive && (
        <g transform={`scale(${1 / icon.scale})`}>
          {(['nw', 'ne', 'se', 'sw'] as const).map((corner) => {
            const { cx, cy } = getCirclePosition(corner,icon)
            return (
              <circle
                key={corner}
                cx={cx}
                cy={cy}
                r="6"
                fill={transformType === corner ? 'yellow' : 'white'}
                stroke="black"
                strokeWidth="2"
                style={{ cursor: `${corner}-resize` }}
                onMouseDown={(e) => handleTransformStart(e, corner)}
                onTouchStart={(e) => handleTransformStart(e, corner)}
              />
            )
          })}
        </g>
      )}
    </g>
  )
}

const TextBox: React.FC<{
  textBox: TextBoxType
  updateTextBox: (id: number, updates: Partial<TextBoxType>) => void
  deleteTextBox: (id: number) => void
  isActive: boolean
  setActiveTextBox: (id: number, event: React.MouseEvent | React.TouchEvent) => void;
}> = ({ textBox, updateTextBox, deleteTextBox, isActive, setActiveTextBox }) => {
  const [isMoving, setIsMoving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editedText, setEditedText] = useState(textBox.text)
  const [transformType, setTransformType] = useState<'nw' | 'ne' | 'sw' | 'se' | null>(null)
  const textBoxRef = useRef<SVGGElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleMouseDown = (event: React.MouseEvent | React.TouchEvent) => {
    const isTouch = 'touches' in event;
    const startX = isTouch ? event.touches[0].clientX : event.clientX;
    const startY = isTouch ? event.touches[0].clientY : event.clientY;
    const startTextBoxX = textBox.x;
    const startTextBoxY = textBox.y;

    setActiveTextBox(textBox.id, event);
    setIsMoving(true);

    const handleMove = (moveEvent: MouseEvent | TouchEvent) => {
      const moveX = 'touches' in moveEvent ? moveEvent.touches[0].clientX : moveEvent.clientX;
      const moveY = 'touches' in moveEvent ? moveEvent.touches[0].clientY : moveEvent.clientY;

      const dx = moveX - startX;
      const dy = moveY - startY;

      updateTextBox(textBox.id, { x: startTextBoxX + dx, y: startTextBoxY + dy });
    };

    const handleEnd = (endEvent: MouseEvent | TouchEvent) => {
      const endX = 'touches' in endEvent ? endEvent.changedTouches[0].clientX : endEvent.clientX;
      const endY = 'touches' in endEvent ? endEvent.changedTouches[0].clientY : endEvent.clientY;

      const finalX = startTextBoxX + (endX - startX);
      const finalY = startTextBoxY + (endY - startY);

      updateTextBox(textBox.id, { x: finalX, y: finalY });
      setIsMoving(false);

      // Clean up event listeners
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('touchmove', handleMove);
      window.removeEventListener('touchend', handleEnd);
    };

    // Add event listeners for both mouse and touch
    window.addEventListener('mousemove', handleMove);
    window.addEventListener('mouseup', handleEnd);
    window.addEventListener('touchmove', handleMove);
    window.addEventListener('touchend', handleEnd);
  };


  const handleTransformStart = (
    event: React.MouseEvent | React.TouchEvent,
    corner: 'nw' | 'ne' | 'sw' | 'se'
  ) => {
    event.stopPropagation();
    setTransformType(corner);

    const isTouch = 'touches' in event;
    const startX = isTouch ? event.touches[0].clientX : event.clientX;
    const startY = isTouch ? event.touches[0].clientY : event.clientY;

    const textBoxRect = textBoxRef.current?.getBoundingClientRect();
    const centerX = textBoxRect ? textBoxRect.left + textBoxRect.width / 2 : textBox.x;
    const centerY = textBoxRect ? textBoxRect.top + textBoxRect.height / 2 : textBox.y;

    const initialDistance = Math.sqrt(
      Math.pow(startX - centerX, 2) + Math.pow(startY - centerY, 2)
    );
    const initialAngle = Math.atan2(startY - centerY, startX - centerX);
    const initialScale = textBox.scale;

    const handleTransformMove = (moveEvent: MouseEvent | TouchEvent) => {
      const moveX = 'touches' in moveEvent ? moveEvent.touches[0].clientX : moveEvent.clientX;
      const moveY = 'touches' in moveEvent ? moveEvent.touches[0].clientY : moveEvent.clientY;

      const dx = moveX - centerX;
      const dy = moveY - centerY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const angle = Math.atan2(dy, dx);

      const newScale = (distance / initialDistance) * initialScale;
      const newRotation = ((angle - initialAngle) * 180) / Math.PI + textBox.rotation;

      updateTextBox(textBox.id, { rotation: newRotation, scale: newScale });
    };

    const handleTransformEnd = () => {
      setTransformType(null);

      // Cleanup event listeners
      window.removeEventListener('mousemove', handleTransformMove);
      window.removeEventListener('mouseup', handleTransformEnd);
      window.removeEventListener('touchmove', handleTransformMove);
      window.removeEventListener('touchend', handleTransformEnd);
    };

    // Add event listeners for both mouse and touch
    window.addEventListener('mousemove', handleTransformMove);
    window.addEventListener('mouseup', handleTransformEnd);
    window.addEventListener('touchmove', handleTransformMove);
    window.addEventListener('touchend', handleTransformEnd);
  };


  const handleDoubleClick = () => {
    setIsEditing(true)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      updateTextBox(textBox.id, { text: editedText })
      setIsEditing(false)
    }
  }

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  const getCirclePosition = (corner: 'nw' | 'ne' | 'sw' | 'se') => {
    const width = 150
    const height = 20
    switch (corner) {
      case 'nw': return { cx: -width / 2, cy: -height / 2 }
      case 'ne': return { cx: width / 2, cy: -height / 2 }
      case 'se': return { cx: width / 2, cy: height / 2 }
      case 'sw': return { cx: -width / 2, cy: height / 2 }
    }
  }

  return (
    <g
      ref={textBoxRef}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onClick={(event) => setActiveTextBox(textBox.id, event)}
      onDoubleClick={handleDoubleClick}
      onTouchEnd={handleDoubleClick}
      transform={`translate(${textBox.x}, ${textBox.y}) rotate(${textBox.rotation}) scale(${textBox.scale})`}
      style={{ cursor: isMoving ? 'grabbing' : 'grab' }}
    >
      {isEditing ? (
        <foreignObject width="200" height="40" x="-100" y="-20">
          <input
            ref={inputRef}
            type="text"
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={() => {
              updateTextBox(textBox.id, { text: editedText })
              setIsEditing(false)
            }}
            className="w-full h-full text-center bg-transparent text-white border-none outline-none"
            style={{ fontSize: '16px' }}
          />
        </foreignObject>
      ) : (
        <text
          fill={isActive ? 'gray' : 'white'}
          fontSize="16"
          textAnchor="middle"
          y="5"
        >
          {textBox.text}
        </text>
      )}
      {isActive && (
        <g transform={`scale(${1 / textBox.scale})`}>
          {(['nw', 'ne', 'se', 'sw'] as const).map((corner) => {
            const { cx, cy } = getCirclePosition(corner)
            return (
              <circle
                key={corner}
                cx={cx}
                cy={cy}
                r="6"
                fill={transformType === corner ? 'yellow' : 'white'}
                stroke="black"
                strokeWidth="2"
                style={{ cursor: `${corner}-resize` }}
                onMouseDown={(e) => handleTransformStart(e, corner)}
                onTouchStart={(e) => handleTransformStart(e, corner)}
              />
            )
          })}
        </g>
      )}
    </g>
  )
}

export default function Collage({ icons = ['smashing-the-datriarchy', 'code-names', 'friends-to-lovers'] }: { icons?: string[] }) {
  const [activeToolbarButton, setActiveToolbarButton] = useState<number | null>(null)
  const [activeColorButton, setActiveColorButton] = useState(0)
  const [phoneIcons, setPhoneIcons] = useState<IconType[]>([])
  const [textBoxes, setTextBoxes] = useState<TextBoxType[]>([])
  const [draggedIcon, setDraggedIcon] = useState<IconType | null>(null)
  const [activeIconId, setActiveIconId] = useState<number | null>(null)
  const [activeTextBoxId, setActiveTextBoxId] = useState<number | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [savedImage, setSavedImage] = useState<string | null>(null)
  const [heldIconId, setHeldIconId] = useState<number | null>(null);
  const [isSaveVisible, setIsSaveVisible] = useState(false)
  const [isOverTrashBin, setIsOverTrashBin] = useState(false)
  const [resolution, setResolution] = useState(0)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSaveOpen, setIsSaveOpen] = useState(false)
  const [isBackOpen, setIsBackOpen] = useState(false)
  const navigate = useNavigate()

  const colors = ['#272626','#FD267D','#2961EC','#FFEE51']

  const toolbar = ['stickers', 'text']

  const phoneRef = useRef<HTMLDivElement>(null)
  const trashBinRef = useRef<SVGGElement>(null)
  const textRef = useRef<HTMLButtonElement | null>(null);

  const updateIcon = useCallback((id: number, updates: Partial<IconType>) => {
    setPhoneIcons((prevIcons) =>
      prevIcons.map((icon) => (icon.id === id ? { ...icon, ...updates } : icon))
    )
  }, [])

  const selectNothing = useCallback(() => {
    setActiveIconId(null)
    setActiveTextBoxId(null)
  }, [])

  const deleteIcon = useCallback((id: number) => {
    setPhoneIcons((prevIcons) => prevIcons.filter((icon) => icon.id !== id))
    setActiveIconId(null)
    setActiveTextBoxId(null)
  }, [])

  const updateTextBox = useCallback((id: number, updates: Partial<TextBoxType>) => {
    setTextBoxes((prevTextBoxes) =>
      prevTextBoxes.map((textBox) => (textBox.id === id ? { ...textBox, ...updates } : textBox))
    )
  }, [])

  const deleteTextBox = useCallback((id: number) => {
    setTextBoxes((prevTextBoxes) => prevTextBoxes.filter((textBox) => textBox.id !== id))
    setActiveTextBoxId(null)
  }, [])

  const handleAddText = useCallback(() => {
    const newTextBox: TextBoxType = {
      id: Date.now(),
      text: 'Start typing...',
      x: 140,
      y: 300,
      rotation: 0,
      scale: 1,
    }
    setTextBoxes((prevTextBoxes) => [...prevTextBoxes, newTextBox])
    setIsMenuOpen(false)
  }, [])

  const normalizeEvent = (event: React.MouseEvent | React.TouchEvent) => {
    if ('touches' in event && event.touches.length > 0) {
      return {
        clientX: event.touches[0].clientX,
        clientY: event.touches[0].clientY,
      };
    } else if ('changedTouches' in event && event.changedTouches.length > 0) {
      return {
        clientX: event.changedTouches[0].clientX,
        clientY: event.changedTouches[0].clientY,
      };
    } else if ('clientX' in event && 'clientY' in event) {
      return {
        clientX: event.clientX,
        clientY: event.clientY,
      };
    }
    throw new Error('Unsupported event type');
  };

  const handleInteractionStart = useCallback((icon: string, event: React.MouseEvent | React.TouchEvent) => {
    const { clientX, clientY } = normalizeEvent(event);
    setDraggedIcon({
      id: Date.now(),
      name: icon,
      x: clientX,
      y: clientY,
      rotation: 0,
      scale: 1,
    });
    setActiveIconId(null);
    setActiveTextBoxId(null);
    setIsMenuOpen(false);
  }, []);

  const handleInteractionMove = useCallback((event: React.MouseEvent | React.TouchEvent) => {
    const { clientX, clientY } = normalizeEvent(event);

    if (draggedIcon) {
      setDraggedIcon({ ...draggedIcon, x: clientX, y: clientY });
    }
    if (activeIconId !== null && trashBinRef.current) {
      const trashBinRect = trashBinRef.current.getBoundingClientRect();
      const isOver =
        clientX >= trashBinRect.left &&
        clientX <= trashBinRect.right &&
        clientY >= trashBinRect.top &&
        clientY <= trashBinRect.bottom;
      setIsOverTrashBin(isOver);
    }
  }, [draggedIcon, activeIconId]);

  const handleInteractionEnd = useCallback((event: React.MouseEvent | React.TouchEvent) => {
    const { clientX, clientY } = normalizeEvent(event);

    if (draggedIcon && phoneRef.current) {
      const phoneRect = phoneRef.current.getBoundingClientRect();
      if (
        clientX >= phoneRect.left &&
        clientX <= phoneRect.right &&
        clientY >= phoneRect.top &&
        clientY <= phoneRect.bottom
      ) {
        const newIcon: IconType = {
          ...draggedIcon,
          x: clientX - phoneRect.left,
          y: clientY - phoneRect.top,
        };
        setPhoneIcons((prevIcons) => [...prevIcons, newIcon]);
        setActiveIconId(newIcon.id);
        setActiveTextBoxId(null);
      }
    }
    setDraggedIcon(null);
    setIsOverTrashBin(false);
  }, [draggedIcon]);


  useEffect(() => {
    setResolution(window.innerWidth < 768 ? 1 : 0)

    if (phoneRef.current && activeIconId === null && activeTextBoxId === null && isSaving) {
      // Proceed only after states are reset
      const s = phoneRef.current.querySelector('svg')!
      
      if (phoneRef.current && s) {
          // Function to append external SVG content to a target SVG element
          const appendExternalSVG = (filePath: string, targetSVG: SVGSVGElement): Promise<void> => {
            return fetch(filePath)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(`Failed to fetch SVG: ${response.statusText}`);
                }
                return response.text();
              })
              .then((svgText) => {
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
                const externalSVG = svgDoc.querySelector('svg');

                if (!externalSVG) {
                  throw new Error('No valid <svg> found in the external file.');
                }

                if (!targetSVG) {
                  throw new Error(`Target SVG element not found for selector: ${targetSVG}`);
                }

                externalSVG.setAttribute('id','watermark')
                externalSVG.setAttribute('transform', 'translate(120,450)')

                // Move children from external SVG to target SVG
                targetSVG.append(externalSVG);

                let svgData = new XMLSerializer().serializeToString(targetSVG)
                
                svgData = btoa(svgData);

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d')!;
                const img = new Image();
                img.src = 'data:image/svg+xml;base64,' + svgData;
                img.onload = () => {
                  canvas.width = 300;
                  canvas.height = 550;
                  ctx.drawImage(img, 0, 0);
                  const jpgData = canvas.toDataURL('image/jpeg');
                  setSavedImage(jpgData);
                  setIsSaveVisible(true);
                };
              });
          };

          // Usage
          appendExternalSVG('/assets/icons/year-in-swipe-logo-final.svg', s);
      }
    }

    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false)
      }
    }
    
    // Add event listeners for both mouse and touch interactions
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousemove', handleInteractionMove as any);
    document.addEventListener('mouseup', handleInteractionEnd as any);
    document.addEventListener('touchmove', handleInteractionMove as any);
    document.addEventListener('touchend', handleInteractionEnd as any);

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousemove', handleInteractionMove as any);
      document.removeEventListener('mouseup', handleInteractionEnd as any);
      document.removeEventListener('touchmove', handleInteractionMove as any);
      document.removeEventListener('touchend', handleInteractionEnd as any);
    };

  }, [handleInteractionMove, handleInteractionEnd, activeIconId, activeTextBoxId, isSaving, phoneIcons, draggedIcon])

  const handlePhoneClick = useCallback((event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      setActiveIconId(null)
      setActiveTextBoxId(null)
    }
  }, [])

  const confirmDiscard = useCallback(() => {
    navigate("/")
  }, [navigate])

  const handleSave = useCallback(() => {
    if (phoneRef.current) {
      setIsSaving(true)
      setActiveIconId(null)
      setActiveTextBoxId(null)
      setIsSaveOpen(false)
      setIsBackOpen(false)
    }
  }, [])

  const showSaveModal = useCallback(() => {
    setIsSaveOpen(true)
  }, [])

  const showBackModal = useCallback(() => {
    setIsBackOpen(true)
  }, [])

  const handleDownload = useCallback(() => {
    if (savedImage) {
      const link = document.createElement('a')
      link.href = savedImage
      link.download = 'year-in-swipe-2024-vision-board.jpg'
      
      // Create canvas and render the image
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;
      const img = new Image();
      
      img.onload = async () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // Convert canvas to Blob
        canvas.toBlob(async (blob) => {
          if (!blob) {
            console.error('Failed to create blob from canvas');
            return;
          }
          
          // Create File object from Blob
          const file = new File([blob], 'year-in-swipe-2024-vision-board.jpg', { type: 'image/jpg' });

          if (blob && navigator.canShare && navigator.canShare({ files: [file] })) {
            try {
              await navigator.share({
                files: [file],
                title: 'Shared Image',
                text: 'Check out this SVG image!',
              });
              console.log('File shared successfully');
            } catch (error) {
              console.error('Error sharing file:', error);
            }
          } else {
            console.log('File sharing is not supported on this device/browser.');
          }
        }, 'image/png'); // Specify the format
      };

      img.src = savedImage

      link.click()
    }
  }, [savedImage])

  const handleShare = useCallback((platform: string) => {
    if (savedImage) {
      const shareData = {
        title: 'My Vision Board',
        text: 'Check out my 2024 Vision Board!',
        url: savedImage,
      }

      if (navigator.share) {
        navigator.share(shareData)
      } else {
        // Fallback for browsers that don't support Web Share API
        let shareUrl = ''
        switch (platform) {
          case 'facebook':
            alert('Sharing is not directly supported. You can save the image and share it manually.')
            break
          case 'instagram':
            alert('Sharing is not directly supported. You can save the image and share it manually.') 
            return
          case 'twitter':
            alert('Sharing is not directly supported. You can save the image and share it manually.')
            break
        }
        window.open(shareUrl, '_blank')
      }
    }
  }, [savedImage])

  const handleMakeAnother = useCallback(() => {
    setPhoneIcons([])
    setTextBoxes([])
    setIsSaveVisible(false)
    setSavedImage(null)
    setIsSaving(false)
    closeModal()
  }, [])

  const handleDeleteAttempt = useCallback((id: number, x: number, y: number) => {
    if (phoneRef.current) {
      const phoneRect = phoneRef.current.getBoundingClientRect()

      const deleteButtonRect = {
        left:  140+phoneRect.left, // Adjust these values based on your delete button position
        right: 200+phoneRect.left,
        top:  500+phoneRect.top,
        bottom: 550+phoneRect.bottom,
      }

      if (
        x >= deleteButtonRect.left &&
        x <= deleteButtonRect.right &&
        y >= deleteButtonRect.top &&
        y <= deleteButtonRect.bottom
      ) {
        deleteIcon(id)
      }
    }
  }, [deleteIcon])

  const handleIconClick = useCallback((id: number, event: React.MouseEvent | React.TouchEvent) => {
    event.stopPropagation()
    setActiveIconId(id)
    setActiveTextBoxId(null)
  }, [])

  const handleTextBoxClick = useCallback((id: number, event: React.MouseEvent | React.TouchEvent) => {
    event.stopPropagation()
    setActiveIconId(null)
    setActiveTextBoxId(id)
  }, [])

  const closeModal = () => {
    const watermark = document.getElementById('watermark')
    if (watermark) watermark.remove()
    setIsSaveOpen(false)
    setIsBackOpen(false)
    setIsSaving(false)
  }

  return (
    <div className="flex flex-col h-screen bg-black text-white" id="collage">
      <header className="flex justify-between items-center p-md-4 p-2">
        <button className="rounded-full btn-outline btn-yellow p-2 m-1 px-4 px-md-4 py-md-2 w-sm-25" onClick={showBackModal}>Go Back</button>
        <h1 className="text-2xl font-bold pink-drop text-white d-none d-md-block">Year In Swipe™ 2024</h1>
        <h4 className="text-2xl font-bold pink-drop text-white d-md-none d-block">Year In Swipe™ 2024</h4>
        <button className="rounded-full btn-outline btn-yellow p-2 m-1 px-4 px-md-5 py-md-2 w-sm-25" onClick={showSaveModal}>Save</button>
      </header>

      {isBackOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center" id="back">
          <div className="relative z-40 max-w-md text-center">
            <div className="modal text-center p-5">
                <svg width="469" height="319" viewBox="0 0 469 319" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="4" y="4" width="461" height="311" fill="#D9D9D9" stroke="#FFEE51" strokeWidth="8"/>
                  <g filter="url(#filter0_ii_81_5284)">
                    <rect width="469" height="39" fill="#FFEE51"/>

                    <g onClick={closeModal} className="cursor-pointer" transform="translate(440,5)">
                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="24" height="24" fill="#D9D9D9" stroke="black"/>
                        <path d="M20 6L5 21.9299" stroke="black" strokeWidth="2.19784" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5 6L20 21.9299" stroke="black" strokeWidth="2.19784" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </g>

                    <text x="30" y="30" fontSize="18" fontWeight="bold" fill="#000" fontFamily="B612 Mono">Go Back.txt</text>

                    <foreignObject x="30" y="60" width="400" height="300">
                      <div className="text-lg text-black text-center">
                        <h1><strong>Are You Sure?</strong></h1>
                        <p className="m-4">If you leave now, you’ll lose this design. This can’t be undone.</p>
                        <p><button className="rounded-full btn-yellow-modal text-black px-4 py-2" onClick={confirmDiscard}>Discard</button></p>
                        <p><button className="rounded-full text-black px-4 py-2" onClick={closeModal}>Cancel</button></p>
                      </div>
                    </foreignObject>

                  </g>
                  <defs>
                    <filter id="filter0_ii_81_5284" x="-4" y="-4" width="477" height="470" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="5" dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_81_5284"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="-4" dy="-4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="effect1_innerShadow_81_5284" result="effect2_innerShadow_81_5284"/>
                    </filter>
                  </defs>
                </svg>
            </div>
          </div>
        </div>
      )}

      {isSaveOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center" id="save">
          <div className="relative z-40 max-w-md text-center">
            <div className="modal text-center p-5">
                <svg width="469" height="319" viewBox="0 0 469 319" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="4" y="4" width="461" height="311" fill="#D9D9D9" stroke="#FFEE51" strokeWidth="8"/>
                  <g filter="url(#filter0_ii_81_5284)">
                    <rect width="469" height="39" fill="#FFEE51"/>

                    <g onClick={closeModal} className="cursor-pointer" transform="translate(440,5)">
                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="24" height="24" fill="#D9D9D9" stroke="black"/>
                        <path d="M20 6L5 21.9299" stroke="black" strokeWidth="2.19784" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5 6L20 21.9299" stroke="black" strokeWidth="2.19784" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </g>

                    <text x="30" y="30" fontSize="18" fontWeight="bold" fill="#000" fontFamily="B612 Mono">Save?.txt</text>

                    <foreignObject x="30" y="60" width="400" height="300">
                      <div className="text-lg text-black text-center">
                        <h1><strong>Ready to Manifest?</strong></h1>
                        <p className="my-2">You won’t be able to make changes to  this design once you save it.</p>
                        <p><button className="mt-3 rounded-full btn-yellow-modal text-black px-4 py-2" onClick={handleSave}>Save</button></p>
                        <p><button className="rounded-full text-black px-4 py-2" onClick={closeModal}>Cancel</button></p>
                      </div>
                    </foreignObject>

                  </g>
                  <defs>
                    <filter id="filter0_ii_81_5284" x="-4" y="-4" width="477" height="470" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="5" dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_81_5284"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dx="-4" dy="-4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="effect1_innerShadow_81_5284" result="effect2_innerShadow_81_5284"/>
                    </filter>
                  </defs>
                </svg>
            </div>
          </div>
        </div>
      )}

      {resolution===1 && 
        <div className="my-2 w-100 flex items-center justify-between">
          {isMenuOpen &&
            <nav className="md:flex items-center">
              <div className="pt-4 h-16 flex items-center ">
                {toolbar.map((_, index) => (
                  <button
                    key={index}
                    className={`w-[100px] h-[100px] text-center d-none d-md-block ${
                      activeToolbarButton === index ? 'bg-yis-grey' : 'hover:bg-yis-grey'
                    }`}
                    onClick={() => setActiveToolbarButton(index)}
                    aria-label={`Toolbar button ${index + 1}`}
                  >
                      <img
                      src={`/assets/${_}.svg`}
                        width="20"
                        height="20"
                        className={`${_} d-inline`}
                        alt=""
                      /><br />
                      <small>{_}</small>
                  </button>
                ))}
              </div>
              <div className="w-100 overflow-y-auto">
                <div className="w-100 bg-gray-500">
                  {activeToolbarButton === 0 && icons.length > 0 ? (
                    icons.map((icon, index) => (
                      <button
                        key={index}
                        className="bg-yis-grey p-2 rounded-lg shadow inline-block items-center justify-center hover:bg-gray-700 group w-1/3"
                        onMouseDown={(e) => handleInteractionStart(icon, e)}
                        onTouchStart={(e) => handleInteractionStart(icon, e)}
                        aria-label={`Add ${icon} icon to phone`}
                      >
                        <IconSVG name={icon} className="stroke-gray-400 group-hover:stroke-white p-1 m-1 w-30" />
                      </button>
                    ))
                  ) : activeToolbarButton === 1 ? (
                    <button
                      className="w-100 bg-yis-grey p-4 rounded-lg shadow flex items-center justify-center hover:bg-gray-700 d-none d-md-block"
                      onClick={handleAddText}
                      ref={textRef}
                    >
                      Add Text Box
                    </button>
                  ) : (
                    <div className="col-span-3 text-center text-gray-500">
                      No options available
                    </div>
                  )}
                </div>
              </div>
          </nav>}
          <footer
            className={`md:hidden fixed bottom-0 left-0 w-full px-4 py-2 flex justify-center items-center bg-gray-500 text-white rounded-t-lg shadow-md hover:bg-blue-600 transition ${
              isMenuOpen ? 'translate-y-0' : 'translate-y-full'
            }`}
          >
            {toolbar.map((_, index) => (
              <button
                key={index}
                className={`p-3 text-center ${
                  activeToolbarButton === index ? 'bg-yis-grey' : 'hover:bg-yis-grey'
                }`}
                onClick={() => {setActiveToolbarButton(index); if(_ === "text"){handleAddText();}if(_ === "stickers"){setIsMenuOpen(!isMenuOpen);}}}
                aria-label={`Toolbar button ${index + 1}`}
              >
                  <img
                  src={`/assets/${_}.svg`}
                    width="20"
                    height="20"
                    className={`${_} d-inline`}
                    alt=""
                  /><br />
                  <small>{_}</small>
              </button>
            ))}
          </footer>
        </div>
      }
      <div className="d-md-flex flex-1 overflow-hidden">
        {isSaveVisible &&
          <Save savedImage={savedImage} onDownload={handleDownload} onMakeAnother={handleMakeAnother} onShare={handleShare} onCancel={() => {setIsSaveVisible(false); setIsSaving(false); setPhoneIcons(phoneIcons.filter(item => item.id !== 75))}}/>
        }
        {!isSaveVisible && resolution === 0 &&
          <div className="pe-1 flex flex-col pt-4">
            {toolbar.map((_, index) => (
              <button
                key={index}
                className={`p-3 text-center d-md-block d-none ${
                  activeToolbarButton === index ? 'bg-yis-grey' : 'hover:bg-yis-grey'
                }`}
                onClick={() => {setActiveToolbarButton(index)}}
                aria-label={`Toolbar button ${index + 1}`}
              >
                  <img
                  src={`/assets/${_}.svg`}
                    width="20"
                    height="20"
                    className={`${_} d-inline`}
                    alt=""
                  /><br />
                  <small>{_}</small>
              </button>
            ))}
          </div>
        }
        {!isSaveVisible && resolution === 0 &&
          <div className="w-2/3 pt-4 overflow-y-auto bg-yis-grey">
            {activeToolbarButton === 0 && icons.length > 0 ? (

              <div>
                <h1 className="ms-4 font-bold text-white text-uppercase">Stickers</h1>
                <div className="grid grid-cols-3 flex justify-center items-center">
                {icons.map((icon, index) => (

                  <div className="relative group" key={index}>
                    <button
                      className="bg-yis-grey p-4 rounded-lg hover:bg-gray-700 group"
                      onMouseDown={(e) => handleInteractionStart(icon, e)}
                      onTouchStart={(e) => handleInteractionStart(icon, e)}
                      aria-label={`Add ${icon} icon to phone`}
                    >
                      <IconSVG name={icon} className="stroke-gray-400 group-hover:stroke-white" />
                      <p><small>{icon.split('.')[0].split("-").map((w) => w[0].toUpperCase()+w.substr(1)).join(" ")}</small></p>
                    </button>
                    <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max bg-gray-800 text-white text-sm rounded-md px-3 py-1 opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 transition-opacity">
                      Tooltip with focus support
                    </div>
                  </div>

                ))}
                </div>
              </div>
              ) : activeToolbarButton === 1 ? (
               <div className="w-full flex items-center justify-start bg-yis-grey" style={{height:"10em"}}>
                <button
                  className="bg-white py-2 px-5 m-4 text-black shadow flex items-center justify-center hover:bg-gray-200 rounded-full"
                  onClick={handleAddText}
                >
                  <img
                    src={`/assets/text-black.svg`}
                    width="20"
                    height="20"
                    className="inline-block me-3"
                    alt="Text Icon"
                  /> 
                  Add a text box
                </button>
              </div>
              ) : (
                <div className="col-span-3 text-center text-gray-500">
                  No options available
                </div>
              )}
          </div>
        }
        {!isSaveVisible && ((!isMenuOpen && !draggedIcon) || (draggedIcon)) &&
          <div className="flex-1 pb-4 flex flex-col items-center justify-center">
            <div
              ref={phoneRef}
              className={`w-[300px] h-[550px] border-1 border-gray-600 overflow-hiddenrelative`}
              onClick={handlePhoneClick}
            >
              <svg 
                  id="phone"
                  className="w-full h-full"
                  width="300"
                  height="550"
                  viewBox="0 0 300 550"
                  key={heldIconId}
                  onClick={selectNothing}
                  style={{backgroundColor: colors[activeColorButton]}}
                >
                {phoneIcons.map((icon) => (
                  <PhoneIcon
                    key={icon.id}
                    icon={icon}
                    updateIcon={updateIcon}
                    deleteIcon={deleteIcon}
                    isActive={icon.id === activeIconId}
                    setActiveIcon={setActiveIconId}
                    setHeldIconId={setHeldIconId}
                    onDeleteAttempt={handleDeleteAttempt}
                    isOverTrashBin={isOverTrashBin && icon.id === activeIconId}
                    onClick={handleIconClick}
                  />
                ))}
                {textBoxes.map((textBox) => (
                  <TextBox
                    key={textBox.id}
                    textBox={textBox}
                    updateTextBox={updateTextBox}
                    deleteTextBox={deleteTextBox}
                    isActive={textBox.id === activeTextBoxId}
                    setActiveTextBox={handleTextBoxClick}
                  />
                ))}
                {(activeIconId || activeTextBoxId) && 
                  <g ref={trashBinRef} transform="translate(140, 500)">
                    <circle r="20" fill="black" stroke="white" x="100" y="100" onClick={() => { if (activeTextBoxId) deleteTextBox(activeTextBoxId) }} />
                    <g transform='translate(-12, -12)'>
                      <Trash2
                        size={24}
                        color="white"
                        style={{
                          cursor: 'finger'
                        }}
                      />
                      </g>
                  </g>
                }
              </svg>
            </div>
            <div id="colors" className="mt-4 flex space-x-2">
              {((!activeIconId && !activeTextBoxId) || !resolution) && colors.map((color, index) => (
                <button
                  key={index}
                  className={`w-4 h-4 rounded-full border ${activeColorButton === index ? 'ring-2 ring-offset-2 ring-gray-400' : ''}`}
                  style={{backgroundColor: color}}
                  onClick={() => setActiveColorButton(index)}
                  aria-label={`Select color ${index + 1}`}
                />
              ))}
            </div>
          </div>
        }
      </div>
      {draggedIcon && (
        <div
          style={{
            position: 'fixed',
            left: draggedIcon.x,
            top: draggedIcon.y,
            pointerEvents: 'none',
            zIndex: 1000,
          }}
        >
          <IconSVG name={draggedIcon.name} className="stroke-black" />
        </div>
      )}
      {isModalOpen && (
        <div className="modal text-center">
          <div className="modal-content">
            <h4 className="mt-2 font-bold yellow-drop">
              Tinder Year In Swipe™
            </h4>
            <h1 className="mt-2 font-bold yellow-drop">
               VISION BOARD  MAKER
            </h1>
            <p>
              This interactive space is your gateway to this year’s biggest shifts and a peek into our predictions for 2025—from the rise of  “Loud Looking,” where singles embraced clarity and confidence in their dating preferences, to culture-shifting moments. This collage-style board invites you to explore, get inspired, and craft your own board for the year ahead. 
            </p>
            <h3 className="mt-2 font-bold text-black">Dive in, get creative, and share your vision for what’s next in 2025!</h3>
            <div className="px-4 py-3 sm:px-6">
              <button
                type="button"
                className="py-2 px-3 px-md-5 btn-pink rounded-full"
                onClick={() => setIsModalOpen(false)}
              >
                Start Creating
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}