import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

interface SaveProps {
  savedImage: string | null
  onDownload: () => void
  onMakeAnother: () => void
  onShare: (platform: string) => void
  onCancel: () => void
}

export const Save: React.FC<SaveProps> = ({
  savedImage,
  onDownload,
  onMakeAnother,
  onShare,
  onCancel,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [resolution, setResolution] = useState(0)
  useEffect(() => {
    setResolution(window.innerWidth < 768 ? 1 : 0)
  },[]);

  const shareFiles = async () => {

    // Create canvas and render the image
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      // Convert canvas to Blob
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Failed to create blob from canvas');
          return;
        }

        // Create File object from Blob
        const file = new File([blob], 'year-in-swipe-2024-vision-board.jpg', { type: 'image/jpeg' });
        // Check if sharing is supported
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          // Share the file
          navigator.share({
            files: [file],
            title: 'Shared Image',
            text: 'Check out this vision board!',
          })
            .then(() => console.log('File shared successfully'))
            .catch((error) => console.error('Error sharing file:', error));
        } else {
          console.error('File sharing is not supported on this device/browser.');
        }
      }, 'image/jpeg'); // Specify the output format
    };

    if (savedImage) img.src = savedImage
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-50">
      <header className="sticky top-0 z-50 w-full bg-black shadow-sm">
         <div className="mx-auto px-4 flex h-14 items-center justify-between">
           <div className="flex items-center">
             <Link to="/" className="font-bold text-xl"><img src="/assets/icons/logo.svg" height="40px" alt=""></img></Link>

           </div>
           <nav className="hidden md:flex items-center space-x-6">
             <Link to="/" className="rounded-full btn-outline py-2 px-4 text-white btn-cyan">Vision Board</Link>
             <Link to="/media-kits" className="rounded-full btn-outline py-2 px-4 text-white btn-blue">Media Kits</Link>
           </nav>
           <button
             className="md:hidden p-2"
             onClick={() => setIsMenuOpen(!isMenuOpen)}
             aria-label="Toggle menu"
           >
             <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="white">
               <path d="M4 6h16M4 12h16M4 18h16"></path>
             </svg>
           </button>
         </div>
       </header>
       {isMenuOpen && (
         <div className="fixed inset-0 z-50 bg-black md:hidden">
           <div className="flex flex-col items-center justify-center text-center h-full">
             <Link to="/" className="rounded-full w-50 btn-outline text-xl text-white btn-cyan font-bold my-4 py-2 px-4" onClick={() => setIsMenuOpen(false)}>Vision Board</Link>
             <Link to="/media-kits" className="rounded-full w-50 btn-outline text-xl text-white btn-blue font-bold my-4 py-2 px-4" onClick={() => setIsMenuOpen(false)}>Media Kits</Link>
             <button
               className="absolute top-4 right-4 p-2"
               onClick={() => setIsMenuOpen(false)}
               aria-label="Close menu"
             >
               <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                 <path d="M6 18L18 6M6 6l12 12"></path>
               </svg>
             </button>
           </div>
         </div>
       )}
      <div className="w-full h-full flex flex-col items-center justify-center p-8">
        <div className="flex justify-center w-full max-w-2xl p-3 p-md-5">
          {resolution===0 && <div className="h-full flex flex-col items-center justify-center mx-5">
              <button
                className="px-8 py-2 lg:w-25 md:w-50 w-100 btn-orange btn-outline rounded-full font-bold"
                onClick={onDownload}
              >
                Download
              </button>
            </div>
          }
          {savedImage && (
            <div className="mb-8 max-w-2xl max-h-[60vh] overflow-hidden">
              <img src={savedImage} alt="Saved Vision Board" className="max-w-full max-h-full object-contain border" />
            </div>
          )}
          {resolution===0 && <div className="h-full flex flex-col items-center justify-center mx-5">
          <button
            className="px-8 py-2 lg:w-25 md:w-50 w-100 btn-purple btn-outline rounded-full font-bold d-none d-md-block"
            onClick={onMakeAnother}
          >
            Make Another
          </button>
          </div>
          }
        </div>
        {resolution===1 && <div className="mb-5">
            <button
              className="px-8 py-2 lg:w-25 md:w-50 w-100 my-3 btn-outline btn-blue rounded-full font-bold"
              onClick={shareFiles}
            >
              Share
            </button>
            <button
              className="px-8 py-2 lg:w-25 md:w-50 w-100 mb-3 btn-outline btn-purple rounded-full font-bold"
              onClick={onMakeAnother}
            >
              Make Another
            </button>
          </div>
        }
      </div>
    </div>
  )
}

